export const siteData = {
  "name": "MARIMA",
  "address": "13 AVENUE FERNAND COIN,",
  "postCode": "33140",
  "city": "villenave",
  "phone": "0567385358",
  "url": "https://marima.store",
  "urlSub": "marima.store",
  "price": "price_1Q54CcGxDV5iRorJ06qNf69i",
  "pk": "pk_live_51Q547sGxDV5iRorJtIj7aGg73BKwRPbasZNdxkpfIADh8b8OyvqpJ3fQFRPvo4Zi1MvU5ngLGid3F7kpCIk23bi700hT8zBXx5",
  "sk": "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"
};